<template>
  <div>
    <Table
      :data="tableData.users"
      :columns="tableColumns"
      stripe
    ></Table>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { deleteWorkGroupUser } from '@/api/os/workgroupuser'
export default {
  mixins: [sysMixins],
  data () {
    return {
      list: [],
      tableColumns: [
        { title: '编号', key: 'id', align: 'center' },
        { title: '用户名', key: 'username', align: 'center' },
        { title: '手机号码', key: 'mobile', align: 'center' },
        { title: '用户姓名', key: 'name', align: 'center' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              style: { color: '#ef4f4f' },
              on: {
                click: () => {
                  this.params = params
                  this.hanldDeleteUser()
                }
              }
            }, '删除')
          }
        }
      ]
    }
  },
  computed: {
    workGroupId () {
      return this.$store.state.workGroup.workGroupId
    },
    tableData () {
      return this.$store.state.workGroup.workGroupUserData
    }
  },
  created () {
    this.getTableData()
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'groupDetail', value: '小组详情' })
    this.setLinkRouterMaps(['groupDetail', () => { this.handelPage() }])
    this.setActivedTagName('groupDetail')
  },
  methods: {
    handelPage () {
      this.setShowLeftBlock(true)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('AddGroupUser')
      this.setBottomComponent('GroupDetail')
      this.setRightComponent('')
    },
    getTableData () {
      this.$store.dispatch('getWorkGroupUserData')
    },
    hanldDeleteUser (obj) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该条数据',
        onOk: () => {
          const postData = {
            workgroupId: this.workGroupId,
            userId: this.params.row.id
          }
          deleteWorkGroupUser(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '移除小组成员成功' })
              this.getTableData()
            }
          })
        }
      })
    }
  }
}
</script>
