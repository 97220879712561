import request from '@/utils/requestV2'
const qs = require('qs')

// 新增运维小组用户
export function addWorkGroupUser (data) {
  return request({
    url: '/ooh-os/v1/workgroupuser/addworkgroupuser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除运维小组用户
export function deleteWorkGroupUser (data) {
  return request({
    url: '/ooh-os/v1/workgroupuser/deleteworkgroupuser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更新运维小组组长
export function updateLeader (data) {
  return request({
    url: '/ooh-os/v1/workgroupuser/updateleader',
    method: 'post',
    data: qs.stringify(data)
  })
}
